export const appetizers = [
  {
    name: "Delicious Egg Roll",
    price: "$1.95",
    description: "",
  },
  {
    name: "Delicious Spring Roll",
    price: "$1.95",
    description: "",
  },
  {
    name: "B.B.Q Pork Ribs",
    price: "$15.95",
    description: "",
  },
  {
    name: "B.B.Q Pork Slices",
    price: "$14.95",
    description: "",
  },
  {
    name: "Chicken Wings (crispy)",
    price: "$12.95",
    description: "",
  },
  {
    name: "Almond Soo Guy",
    price: "$13.95",
    description: "",
  },
  {
    name: "Deep Fried Wontons",
    price: "$6.95",
    description: "",
  },
  {
    name: "Chicken Teriyaki",
    price: "$15.95",
    description: "",
  },
  {
    name: "Beef Teriyaki",
    price: "$15.95",
    description: "",
  },
  {
    name: "Breaded Shrimp",
    price: "$12.95",
    description: "",
  },
  {
    name: "Breaded Scallops",
    price: "$16.95",
    description: "",
  },
  {
    name: "Bo Bo Tray For Two",
    price: "$22.95",
    description: "",
  },
  {
    name: "Bo Bo Tray for Four",
    price: "$43.95",
    description: "",
  },
];

export const soups = [
  {
    name: "Chicken, Beef or Pork Noodle Soup",
    price: "$5.95",
    description: "",
  },
  {
    name: "Hot and Sour Soup",
    price: "$5.95",
    description: "",
  },
  {
    name: "Wonton Soup (L)",
    price: "$5.95",
    description: "",
  },
  {
    name: "Wonton Soup (S)",
    price: "$5.50",
    description: "",
  },
  {
    name: "Egg Flower Soup",
    price: "$5.95",
    description: "",
  },
  {
    name: "Fine Noodle Soup",
    price: "$5.95",
    description: "",
  },
  {
    name: "Consomme Soup",
    price: "$4.00",
    description: "",
  },
  {
    name: "Miso Soup",
    price: "$5.95",
    description: "",
  },
];

export const sweetSour = [
  {
    name: "Sweet and Sour Chicken Balls",
    price: "$12.95",
    description: "",
  },
  {
    name: "Sweet and Sour Pork",
    price: "$11.95",
    description: "",
  },
  {
    name: "Sweet and Sour Spare Ribs",
    price: "$11.95",
    description: "",
  },
  {
    name: "Sweet and Sour Breaded Shrimp",
    price: "$12.95",
    description: "",
  },
  {
    name: "Sweet and Sour Breaded Scallops",
    price: "$16.95",
    description: "",
  },
  {
    name: "Sweet and Sour Beef",
    price: "$12.95",
    description: "",
  },
];

export const chowMein = [
  {
    name: "Chicken and Mushroom Chop Suey",
    price: "$10.95",
    description: "",
  },
  {
    name: "Beef and Mushroom Chop Suey",
    price: "$10.95",
    description: "",
  },
  {
    name: "Pork and Mushroom Chop Suey",
    price: "$10.95",
    description: "",
  },
  {
    name: "Shrimp and Mushroom Chop Suey",
    price: "$13.95",
    description: "",
  },
  {
    name: "Vegetable and Mushroom Chop Suey",
    price: "$9.95",
    description: "",
  },
  {
    name: "Harmony Chop Suey",
    price: "$13.95",
    description: "",
  },
];
export const honeyGarlic = [
  {
    name: "Honey Garlic Chicken Wings",
    price: "$13.95",
    description: "",
  },
  {
    name: "Honey Garlic Spare Ribs",
    price: "$14.95",
    description: "",
  },
  {
    name: "Honey Garlic Shrimps",
    price: "$13.95",
    description: "",
  },
  {
    name: "Honey Garlic Wontons",
    price: "$7.95",
    description: "",
  },
];
export const friedRice = [
  {
    name: "Chicken Fried Rice",
    price: "$10.95",
    description: "",
  },
  {
    name: "Beef Fried Rice",
    price: "$10.95",
    description: "",
  },
  {
    name: "Pork Fried Rice",
    price: "$10.95",
    description: "",
  },
  {
    name: "Mushroom Fried Rice",
    price: "$10.95",
    description: "",
  },
  {
    name: "Vegetable Fried Rice",
    price: "$10.95",
    description: "",
  },
  {
    name: "Shrimp Fried Rice",
    price: "$13.95",
    description: "",
  },
  {
    name: "Harmony Special Fried Rice",
    price: "$13.95",
    description: "",
  },
  {
    name: "Steamed Rice",
    price: "$4.50",
    description: "",
  },
];

export const vegetableAlmond = [
  {
    name: "Stir Fried Mixed Vegetables Almond",
    price: "$12.95",
    description: "",
  },
  {
    name: "Almond Guy Ding (Chicken)",
    price: "$12.95",
    description: "",
  },
  {
    name: "Almond Beef Ding",
    price: "$12.95",
    description: "",
  },
  {
    name: "Almond B.B.Q Pork Ding",
    price: "$12.95",
    description: "",
  },
  {
    name: "Almond Shrimp Ding",
    price: "$14.95",
    description: "",
  },
];

export const loMein = [
  {
    name: "Plain Lo-Mein",
    price: "$12.95",
    description: "",
  },
  {
    name: "Mixed Vegetable Lo-Mein",
    price: "$12.95",
    description: "",
  },
  {
    name: "Chicken Lo-Mein (with Mixed Vegetables)",
    price: "$13.95",
    description: "",
  },
  {
    name: "Pork Lo-Mein (with Mixed Vegetables)",
    price: "$13.95",
    description: "",
  },
  {
    name: "Beef Lo-Mein (with Mixed Vegetables)",
    price: "$13.95",
    description: "",
  },
  {
    name: "Shrimp Lo-Mein (with Mixed Vegetables)",
    price: "$14.95",
    description: "",
  },
  {
    name: "Harmony Special Lo-Mein",
    price: "$14.95",
    description: "",
  },
  {
    name: "Shanghai Chow Mein (Chicken or Beef)",
    price: "$13.95",
    description: "",
  },
  {
    name: "Singapore Chow Mein",
    price: "$13.95",
    description: "",
  },
];

export const eggFuYoung = [
  {
    name: "Chicken Egg Fu Young",
    price: "$13.95",
    description: "",
  },
  {
    name: "B.B.Q Pork Egg Fu Young",
    price: "$13.95",
    description: "",
  },
  {
    name: "Beef Egg Fu Young",
    price: "$13.95",
    description: "",
  },
  {
    name: "Mushroom Egg Fu Young",
    price: "$13.95",
    description: "",
  },
  {
    name: "Shrimp Egg Fu Young",
    price: "$14.95",
    description: "",
  },
];

export const curry = [
  {
    name: "Curry Chicken with Vegetables",
    price: "$13.95",
    description: "",
  },
  {
    name: "Curry Beef with Vegetables",
    price: "$13.95",
    description: "",
  },
  {
    name: "Curry Shrimp with Vegetables",
    price: "$14.95",
    description: "",
  },
  {
    name: "Curry Chicken Wings with Vegetables",
    price: "$14.95",
    description: "",
  },
];

export const szechaun = [
  {
    name: "Spicy Chicken or Beef",
    price: "$13.95",
    description: "",
  },
  {
    name: "Kung Po (Chicken or Beef)",
    price: "$13.95",
    description: "",
  },
  {
    name: "Kung Pao Shrimps",
    price: "$14.95",
    description: "",
  },
  {
    name: "Ginger Beef",
    price: "$14.95",
    description: "",
  },
  {
    name: "Szechuan Chicken Or Beef",
    price: "$13.95",
    description: "",
  },
  {
    name: "Ma Po Tofu (Beef, Chicken Or Pork)",
    price: "$13.95",
    description: "",
  },
];

export const seafood = [
  {
    name: "Shrimp Lobster Sauce",
    price: "$14.95",
    description: "(Stir fried shrimp with minced porkin special sauce)",
  },
  {
    name: "Seafood Platter",
    price: "$16.95",
    description: "(Scallops, shrimp and mixed Chinese vegetables with our own sauce)",
  },
  {
    name: "Soo Chow Scallops",
    price: "$16.95",
    description: "Breaded scallops cooked with Chinese Vegetables)",
  },
  {
    name: "Soo Chow Har Kew",
    price: "$14.95",
    description: "(Breaded shrimp and mixed Chinese Vegetables)",
  },
  {
    name: "Seafood Delight",
    price: "$16.95",
    description: "",
  },
];

export const popularSuggestions = [
  {
    name: "Moo Goo Guy Pan",
    price: "$13.95",
    description: "",
  },
  {
    name: "Chicken Snow Peas",
    price: "$14.95",
    description: "",
  },
  {
    name: "Chicken Mushroom",
    price: "$13.95",
    description: "",
  },
  {
    name: "Chicken Tomato",
    price: "$13.95",
    description: "",
  },
  {
    name: "Shrimp with Green Pepper",
    price: "$14.95",
    description: "",
  },
  {
    name: "Pepper Steak",
    price: "$13.95",
    description: "",
  },
  {
    name: "Beef or Chicken Broccoli",
    price: "$13.95",
    description: "",
  },
  {
    name: "Beef Snow Peas",
    price: "$14.95",
    description: "",
  },
  {
    name: "Beef Tomato",
    price: "$13.95",
    description: "",
  },
  {
    name: "Beef Chinese Green",
    price: "$13.95",
    description: "",
  },
  {
    name: "Beef Mushroom",
    price: "$13.95",
    description: "",
  },
  {
    name: "Beef Mixed Vegetables",
    price: "$13.95",
    description: "",
  },
  {
    name: "Shrimp Snow Peas",
    price: "$15.95",
    description: "",
  },
  {
    name: "Chicken Baby Corn",
    price: "$14.95",
    description: "",
  },
  {
    name: "Mixed Oriental Vegetables",
    price: "$12.95",
    description: "",
  },
];

export const chefSuggestions = [
  {
    name: "Tai Dop Voy",
    price: "$13.95",
    description: "(Chicken, Shrimp, B.B.Q pork and Beef with Chinese vegetables.)",
  },
  {
    name: "Satay Beef or Chicken",
    price: "$13.95",
    description: "",
  },
  {
    name: "Orange Chicken",
    price: "$12.95",
    description: "",
  },
  {
    name: "Chicken or Beef with Black Bean Sauce",
    price: "$13.95",
    description: "(Cubed with black bean sauce and garlic)",
  },
  {
    name: "Pepper Spare Ribs",
    price: "$14.95",
    description: "",
  },
  {
    name: "Beef Mushroom with Oyster Sauce",
    price: "$13.95",
    description: "",
  },
  {
    name: "Wai Guy Kew",
    price: "$13.95",
    description: "(Cubed chicken with Chinese vegetables And mushrooms cooked in a special hot sauce.)",
  },
  {
    name: "Beef and Onion",
    price: "$14.95",
    description: "(Tender beef, onion with our own sauce)",
  },

  {
    name: "Beef or Chicken with Mushrooms and Broccoli",
    price: "$14.95",
    description: "(Beef tenderloin in cubes combined With Chinese vegetables.)",
  },
  {
    name: "General Tao chicken",
    price: "$12.95",
    description: "",
  },
  {
    name: "Woo Dip Har",
    price: "$16.95",
    description: "(Butterfly shrimp with special sauce.)",
  },
  {
    name: "Hong Sew Guy Kew",
    price: "$13.95",
    description: "(Breaded chicken pieces with Chinese Vegetables)",
  },
  {
    name: "Soo Chow Wonton",
    price: "$13.95",
    description: "(Crispy wonton, chicken and B.B.Q pork With Chinese greens)",
  },
  {
    name: "Golden Garlic Spare Ribs",
    price: "$15.95",
    description: "",
  },
  {
    name: "Cantonese Style Chow Mein",
    price: "$14.95",
    description:
      "(A combination of sliced chicken, B.B.Q pork Shrimp and beef with mixed vegetables and fine egg noodles)",
  },
  {
    name: "Lemon Chicken",
    price: "$12.95",
    description: "",
  },
  {
    name: "Sesame Chicken",
    price: "$12.95",
    description: "",
  },
  {
    name: "Scallops and Mixed Vegetables",
    price: "$16.95",
    description: "",
  },
];

export const canadianDishes = [
  {
    name: "Spicy Potato Wedges",
    price: "$7.95",
    description: "",
  },
  {
    name: "Fish and Chips",
    price: "$10.95",
    description: "",
  },
  {
    name: "French Fries",
    price: "$4.95",
    description: "",
  },
  {
    name: "Onion Rings",
    price: "$4.95",
    description: "",
  },
];

// export const dinnerOne = [
//   "Egg Roll",
//   "Chicken Chop Suey",
//   "Chicken Fried Rice",
//   "Sweet & Sour Chicken Balls",
//   "Chicken Wings",
//   "Fortune Cookie",
// ];

export const dinnerTwo = [
  "2 Egg Rolls",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Sweet & Sour Chicken Balls",
  "Fortune Cookies",
];

export const dinnerThree = [
  "3 Egg Rolls",
  "Beef Chop Suey",
  "Chicken Fried Rice",
  "Sweet & Sour Chicken Balls",
  "Sweet & Sour Spare Ribs",
  "Chicken Wings",
  "Fortune Cookies",
];

export const dinnerFour = [
  "4 Egg Rolls",
  "Fried Wontons",
  "Beef Chop Suey",
  "Moo Goo Guy Pan",
  "Chicken Fried Rice",
  "Sweet & Sour Chicken Balls",
  "Chicken Wings",
  "Fortune Cookies",
];

export const dinnerFive = [
  "5 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Beef Chop Suey",
  "Tai Dop Voy",
  "Harmony Fried Rice",
  "Fried Wontons",
  "Chicken Wings",
  "Fortune Cookies",
];

export const dinnerSix = [
  "6 Egg Rolls",
  "Fried Wonton",
  "Sweet & Sour Chicken Balls",
  "Sweet & Sour Shrimp",
  "Harmony Fried Rice",
  "Cantonese Chow Mein",
  "Guy Ding",
  "Beef Chop Suey",
  "Chicken Wings",
  "Fortune Cookies",
];
export const dinnerEight = [
  "8 Egg Rolls",
  "Chicken Balls",
  "Chicken Fried Rice",
  "Lemon Chicken",
  "Beef Broccoli",
  "Beef Chop Suey",
  "Harmony Lo Mein",
  "Sweet & Sour Pork",
  "Chicken Wings",
];
