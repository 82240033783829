import React from "react";
function Footer() {
  return (
    <div id="footer-container" className="footer-container">
      {/* <div className="update">
        <h2>Holiday Update</h2>
        <p>
          {" "}
          Dear customers, please order days in advanced for the following
          holidays:
        </p>
        <ul>
          <li>Christmas Eve</li>
          <li>Christmas Day</li>
          <li>New Years Eve</li>
          <li>New Years Day</li>
        </ul>
        <p>Thank You and Happy Holidays!</p>
        <p>
          (Please be aware wait times may be longer than usual on these days)
        </p>
      </div> */}
      <div className="footer-top">
        <div className="contact">
          <h3 className="footer-title">Contact Us:</h3>
          <a className="phone1" href="tel:519-842-7007">
            519 - 842 - 7007
          </a>
          <a className="phone2" href="tel:519-842-2493">
            519 - 842 - 2493
          </a>
        </div>
        <div className="location">
          <h3 className="footer-title">Location:</h3>
          <p>91 Broadway Street</p>
          <p>Tillsonburg</p>
          <p>Ontario</p>
        </div>
        <div className="hours">
          <h3 className="footer-title">Business Hours:</h3>
          <p>Monday-Sunday</p>
          <p>(11:30AM - 8:00PM)</p>
          <p>
            <b>Note: Most Mondays are closed.</b>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="credit-1">
          <p className="developer">Developed By:</p>
          <p>Kevin Yu</p>
          <p>yu.kevin2002@gmail.com</p>
        </div>
        <div className="credit-2">
          <p className="photographer">Photo Credits:</p>
          <p>
            Ben Taylor <br />
            ben.t1042@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
