import React, { useState } from "react";
import {
  AiFillCaretRight,
  AiFillCaretLeft,
  FaUtensils,
  BiCake,
} from "../icons";
import { buffetPics } from "../Assets/images";
import Navigation from "./Navigation";
import Footer from "./Footer";
function Buffet() {
  const [imgIndex, setImgIndex] = useState(0);
  const nextImg = () => {
    if (imgIndex < 4) {
      setImgIndex(imgIndex + 1);
    }
  };
  const lastImg = () => {
    if (imgIndex > 0) {
      setImgIndex(imgIndex - 1);
    }
  };
  return (
    <>
      <div className="buffet-container">
        <Navigation red={false} />
        <div className="buffet-title">
          <h1>All You Can Eat Buffet</h1>
        </div>

        <div className="buffet-body">
          <div className="buffet-info">
            <div className="price-container">
              <div className="price-icon">
                <FaUtensils />
              </div>
              <div className="price-info">
                <div className="lunch">
                  <p className="lunch-price">Lunch: $16.95</p>
                  <p>(11:30AM - 2:30PM)</p>
                  <p style={{ fontSize: ".9rem" }}>
                    <strong>Available Only Sunday</strong>
                  </p>
                </div>
                <div className="dinner">
                  <p className="dinner-price">Dinner: $20.95</p>
                  <p>(4:30PM - 8:30PM)</p>
                  <p style={{ fontSize: ".9rem" }}>
                    <strong>Available Friday-Sunday</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="birthday-container">
              <div className="birthday-icon">
                <BiCake />
              </div>
              <div className="birthday-info">
                <p className="birthday-title">Free Buffet On Your Birthday</p>
                <p>(Minimum 4 people dine-in)</p>
              </div>
            </div>
          </div>

          <div className="buffet-images">
            <div className="carousel-container">
              <div
                className="images"
                style={{
                  transform: `translateX(${
                    (imgIndex / buffetPics.length) * -100
                  }%)`,
                }}
              >
                {buffetPics.map((img, i) => (
                  <img
                    alt="Harmony Logo"
                    src={img.image}
                    key={i}
                    className="image"
                  />
                ))}
              </div>
            </div>
            <div className="carousel-controls">
              <button onClick={lastImg} className="left-arrow">
                <AiFillCaretLeft
                  style={{
                    color: imgIndex === 0 ? "lightgrey" : "black",
                  }}
                />
              </button>
              <div className="carousel-dots">
                {buffetPics.map((dot, i) => (
                  <div
                    key={i}
                    style={{
                      backgroundColor: imgIndex === i ? "black" : "lightgrey",
                    }}
                    className="carousel-dot"
                  ></div>
                ))}
              </div>
              <button onClick={nextImg} className="right-arrow">
                <AiFillCaretRight
                  style={{
                    color: imgIndex === 4 ? "lightgrey" : "black",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-buffet">
        <Footer />
      </div>
    </>
  );
}
export default Buffet;
